<template>
  <b-container class="form d-flex flex-column align-items-center mb-5 pb-5">
    <b-row>
      <b-col cols="12" md="7">
        <div
          id="form"
          class="postcodeForm rounded py-5 mt-md-5 mt-4 bg-white shadow-lg d-flex flex-column justify-content-center"
        >
          <!-- <p class="m-0 form__heading px-4 px-md-5 mx-3 mx-md-4 pt-3">
            Gefeliciteerd!
          </p>
          <p class="m-0 form__text mt-2 px-4 px-md-5 mx-3 mx-md-4">
            Uw woning komt in aanmerking om het innovatieve alarmsysteem te
            laten plaatsen.
          </p> -->
          <!-- <p class="line mt-3"></p> -->
          <p class="m-0 py-3 px-4 px-lg-5 mx-4 form__title">
            Hoe kan Verisure jou bereiken als je bent <br />
            geselecteerd als tester?
          </p>
          <div class="d-flex justify-content-center">
            <b-form @submit.prevent="onSubmit" class="form__main">
              <b-form-checkbox v-model="isChecked" :style="{ display: 'none' }">
              </b-form-checkbox>
              <b-row>
                <b-col cols="12" lg="6">
                  <b-input-group class="mt-2">
                    <template #prepend>
                      <b-input-group-text
                        ><svg
                          width="20"
                          height="20"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.5 21.5C1.5 18.8478 2.55357 16.3043 4.42893 14.4289C6.3043 12.5536 8.84784 11.5 11.5 11.5C14.1522 11.5 16.6957 12.5536 18.5711 14.4289C20.4464 16.3043 21.5 18.8478 21.5 21.5H1.5Z"
                            stroke="#ED0025"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.5 11.5C14.2614 11.5 16.5 9.26142 16.5 6.5C16.5 3.73858 14.2614 1.5 11.5 1.5C8.73858 1.5 6.5 3.73858 6.5 6.5C6.5 9.26142 8.73858 11.5 11.5 11.5Z"
                            stroke="#ED0025"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </b-input-group-text>
                    </template>
                    <div class="user-input-wrp">
                      <b-form-input
                        class="py-4 inputText"
                        v-model="form.firstname"
                        :state="FnameValidationCheck"
                        placeholder=""
                        required
                      ></b-form-input>
                      <span class="floating-label">Voornaam</span>
                    </div>
                  </b-input-group>
                  <div class="d-flex text-left ml-3">
                    <small class="text-danger" v-if="firstNameValid"
                      >Voornaam niet correct</small
                    >
                  </div>
                </b-col>
                <b-col cols="12" lg="6">
                  <b-input-group class="mt-md-3 mt-lg-2 mt-3">
                    <template #prepend>
                      <b-input-group-text
                        ><svg
                          width="20"
                          height="20"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.5 21.5C1.5 18.8478 2.55357 16.3043 4.42893 14.4289C6.3043 12.5536 8.84784 11.5 11.5 11.5C14.1522 11.5 16.6957 12.5536 18.5711 14.4289C20.4464 16.3043 21.5 18.8478 21.5 21.5H1.5Z"
                            stroke="#ED0025"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M11.5 11.5C14.2614 11.5 16.5 9.26142 16.5 6.5C16.5 3.73858 14.2614 1.5 11.5 1.5C8.73858 1.5 6.5 3.73858 6.5 6.5C6.5 9.26142 8.73858 11.5 11.5 11.5Z"
                            stroke="#ED0025"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </b-input-group-text>
                    </template>
                    <div class="user-input-wrp">
                      <b-form-input
                        class="py-4 inputText"
                        v-model="form.lastname"
                        :state="LnameValidationCheck"
                        placeholder=""
                        required
                      ></b-form-input>
                      <span class="floating-label">Achternaam</span>
                    </div>
                  </b-input-group>
                  <div class="d-flex text-left ml-3">
                    <small class="text-danger" v-if="lastNameValid"
                      >Achternaam niet correct</small
                    >
                  </div>
                </b-col>
              </b-row>
              <b-input-group class="my-3">
                <template #prepend>
                  <b-input-group-text
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 21 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 0.5H1.5C0.947715 0.5 0.5 0.947715 0.5 1.5V13.5C0.5 14.0523 0.947715 14.5 1.5 14.5H19.5C20.0523 14.5 20.5 14.0523 20.5 13.5V1.5C20.5 0.947715 20.0523 0.5 19.5 0.5Z"
                        stroke="#ED0025"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M0.847656 0.743256L9.92671 7.09826C10.0948 7.21591 10.295 7.27901 10.5002 7.27901C10.7053 7.27901 10.9055 7.21591 11.0736 7.09826L20.1527 0.743256"
                        stroke="#ED0025"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.2073 14.2071L12.4922 6.49194"
                        stroke="#ED0025"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.50812 6.49194L0.792969 14.2071"
                        stroke="#ED0025"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </b-input-group-text>
                </template>
                <div class="user-input-wrp">
                  <b-form-input
                    class="py-4 inputText"
                    type="text"
                    v-model="form.email"
                    :state="emailValidationCheck"
                    placeholder=""
                    required
                  ></b-form-input>
                  <span class="floating-label">E-mailadres</span>
                </div>
              </b-input-group>
              <div class="d-flex text-left ml-3">
                <small class="text-danger" v-if="emailValid"
                  >E-mailadres niet correct</small
                >
              </div>
              <b-input-group class="">
                <template #prepend>
                  <b-input-group-text
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9615 14.0703C17.7115 13.8208 17.3726 13.6806 17.0192 13.6806C16.6659 13.6806 16.327 13.8208 16.0769 14.0703L14.6339 15.5134C13.403 16.7457 10.8512 14.8893 9.61435 13.6522C7.80256 11.8406 6.51948 9.37581 7.5082 8.38707L8.95139 6.94404C9.20096 6.69397 9.34112 6.3551 9.34112 6.0018C9.34112 5.6485 9.20096 5.30963 8.95139 5.05956L5.47391 1.58199C5.22381 1.3324 4.88492 1.19223 4.53159 1.19223C4.17827 1.19223 3.83937 1.3324 3.58928 1.58199L2.14628 3.02502C-1.50473 6.67613 1.45865 12.9659 5.75722 17.2644C9.66627 21.1735 16.1602 24.7115 19.9962 20.8754L21.4394 19.4324C21.6889 19.1823 21.829 18.8434 21.829 18.4902C21.829 18.1369 21.6889 17.798 21.4394 17.5479L17.9615 14.0703ZM4.07791 2.07044C4.19845 1.95021 4.36174 1.8827 4.53199 1.8827C4.70223 1.8827 4.86553 1.95021 4.98607 2.07044L8.46276 5.5482C8.58287 5.6686 8.65032 5.83173 8.65032 6.0018C8.65032 6.17187 8.58287 6.335 8.46276 6.4554L7.26398 7.65421L2.87913 3.26925L4.07791 2.07044ZM6.24585 16.776C2.84458 13.3746 -0.743254 7.36852 2.39879 3.76618L6.79746 8.16555C5.78032 9.64965 7.23417 12.2494 9.12552 14.1408C10.5715 15.5866 13.1866 17.415 14.8586 16.2263L19.2551 20.6235C15.6564 23.7627 9.65442 20.1846 6.24585 16.776ZM20.9508 18.9438L19.752 20.1428L15.3672 15.7576L16.5659 14.5588C16.6865 14.4386 16.8498 14.3711 17.02 14.3711C17.1903 14.3711 17.3536 14.4386 17.4741 14.5588L20.9508 18.0371C21.0707 18.1575 21.1381 18.3205 21.1381 18.4904C21.1381 18.6604 21.0707 18.8234 20.9508 18.9438Z"
                        fill="#ED0025"
                      />
                      <path
                        d="M11.414 7.91128C13.206 8.49666 14.3618 9.65263 14.9479 11.4453C14.989 11.5762 15.0556 11.6976 15.1439 11.8025C15.2322 11.9075 15.3405 11.9938 15.4624 12.0566C15.5843 12.1194 15.7175 12.1573 15.8542 12.1683C15.9909 12.1792 16.1285 12.1629 16.2588 12.1203C16.3892 12.0777 16.5098 12.0097 16.6137 11.9201C16.7176 11.8306 16.8026 11.7213 16.864 11.5986C16.9253 11.476 16.9617 11.3423 16.971 11.2055C16.9803 11.0687 16.9624 10.9313 16.9182 10.8015C16.1246 8.37031 14.4899 6.73439 12.0584 5.9413C11.929 5.89904 11.7926 5.88268 11.6569 5.89314C11.5212 5.90361 11.3889 5.9407 11.2675 6.0023C11.1462 6.0639 11.0381 6.1488 10.9496 6.25216C10.861 6.35551 10.7937 6.4753 10.7514 6.60468C10.7092 6.73405 10.6928 6.87049 10.7033 7.00619C10.7137 7.14189 10.7508 7.2742 10.8124 7.39556C10.874 7.51693 10.9589 7.62497 11.0623 7.71353C11.1656 7.80208 11.2854 7.86941 11.4148 7.91167L11.414 7.91128ZM11.4069 6.81889C11.4355 6.73183 11.4974 6.65965 11.5791 6.61816C11.6608 6.57668 11.7556 6.56927 11.8428 6.59757C14.0524 7.31938 15.5392 8.80584 16.2606 11.0155C16.289 11.1026 16.2817 11.1975 16.2402 11.2792C16.1988 11.3609 16.1266 11.4229 16.0395 11.4514C15.9524 11.4786 15.8581 11.4706 15.7767 11.4293C15.6954 11.388 15.6333 11.3166 15.6038 11.2303C14.9454 9.21354 13.6451 7.91345 11.6286 7.25462C11.5415 7.22611 11.4693 7.16424 11.4277 7.08256C11.3862 7.00089 11.3787 6.90607 11.4069 6.81889Z"
                        fill="#ED0025"
                      />
                      <path
                        d="M12.528 5.28306C15.0892 6.1186 16.7409 7.77071 17.5772 10.3314C17.6626 10.5927 17.8483 10.8094 18.0934 10.9338C18.3385 11.0582 18.6231 11.0801 18.8844 10.9948C19.1457 10.9094 19.3624 10.7237 19.4868 10.4786C19.6112 10.2334 19.6331 9.94888 19.5477 9.68757C18.5061 6.49826 16.3611 4.35316 13.1718 3.31171C13.042 3.26757 12.9047 3.24964 12.7678 3.25896C12.631 3.26827 12.4974 3.30466 12.3747 3.36599C12.252 3.42733 12.1428 3.5124 12.0532 3.61628C11.9637 3.72016 11.8956 3.84078 11.853 3.97114C11.8104 4.10151 11.7941 4.23904 11.8051 4.37575C11.816 4.51246 11.854 4.64565 11.9167 4.76758C11.9795 4.88952 12.0659 4.99778 12.1708 5.0861C12.2757 5.17441 12.3972 5.24103 12.528 5.28208V5.28306ZM12.5213 4.19067C12.5354 4.14749 12.5578 4.1075 12.5873 4.07299C12.6168 4.03849 12.6529 4.01014 12.6934 3.98959C12.7338 3.96903 12.778 3.95666 12.8233 3.95319C12.8686 3.94971 12.9141 3.95521 12.9572 3.96935C15.9251 4.93776 17.9215 6.93419 18.8901 9.90297C18.9185 9.9901 18.9111 10.0849 18.8696 10.1666C18.8282 10.2484 18.756 10.3103 18.669 10.3389C18.5819 10.366 18.4876 10.3581 18.4062 10.3168C18.3249 10.2755 18.2628 10.2041 18.2333 10.1178C17.3239 7.33221 15.5285 5.53598 12.742 4.62641C12.6549 4.5977 12.5827 4.53559 12.5413 4.45371C12.4999 4.37183 12.4927 4.27687 12.5213 4.18969V4.19067Z"
                        fill="#ED0025"
                      />
                      <path
                        d="M22.4485 8.89822C21.0811 4.7105 18.2637 1.89216 14.075 0.524943C13.9456 0.482695 13.8092 0.466348 13.6735 0.476836C13.5378 0.487324 13.4054 0.524441 13.2841 0.586068C13.1627 0.647695 13.0547 0.732626 12.9661 0.83601C12.8776 0.939394 12.8102 1.05921 12.768 1.18861C12.7257 1.31801 12.7094 1.45447 12.7199 1.59019C12.7304 1.72591 12.7675 1.85823 12.8291 1.97961C12.8907 2.10098 12.9757 2.20903 13.079 2.29758C13.1824 2.38614 13.3022 2.45346 13.4316 2.49571C17.006 3.66273 19.3106 5.96755 20.4778 9.54185C20.5631 9.80319 20.7487 10.0199 20.9938 10.1444C21.1152 10.2061 21.2475 10.2432 21.3832 10.2537C21.5189 10.2642 21.6554 10.2479 21.7848 10.2056C21.9142 10.1634 22.034 10.0961 22.1374 10.0075C22.2407 9.91899 22.3257 9.81095 22.3873 9.68959C22.449 9.56822 22.4861 9.4359 22.4966 9.30019C22.5071 9.16447 22.4907 9.02802 22.4485 8.89862V8.89822ZM21.57 9.54837C21.483 9.57522 21.389 9.56713 21.3079 9.52581C21.2268 9.48449 21.165 9.41319 21.1356 9.32705C19.8942 5.52826 17.4447 3.07872 13.646 1.83826C13.6024 1.82458 13.562 1.80239 13.527 1.77296C13.492 1.74353 13.4632 1.70745 13.4423 1.66682C13.4213 1.62618 13.4087 1.58179 13.405 1.53623C13.4014 1.49066 13.4068 1.44482 13.421 1.40137C13.4351 1.35791 13.4578 1.3177 13.4876 1.28307C13.5175 1.24844 13.5539 1.22007 13.5948 1.19961C13.6356 1.17915 13.6802 1.16701 13.7258 1.16388C13.7714 1.16076 13.8172 1.16671 13.8604 1.1814C17.8279 2.47675 20.4962 5.14485 21.7919 9.11323C21.8199 9.20025 21.8123 9.29486 21.7707 9.37628C21.7291 9.45771 21.6569 9.51931 21.57 9.54758V9.54837Z"
                        fill="#ED0025"
                      />
                    </svg>
                  </b-input-group-text>
                </template>
                <div class="user-input-wrp">
                  <b-form-input
                    class="py-4 inputText"
                    v-model="form.phone_number"
                    maxLength=""
                    placeholder=""
                    :state="phoneValidationCheck"
                    required
                  ></b-form-input>
                  <span class="floating-label">Telefoonnummer</span>
                </div>
              </b-input-group>
              <div class="d-flex text-left ml-3">
                <small class="text-danger" v-if="phoneValid"
                  >Telefoonnummer niet correct</small
                >
              </div>
              <div class="d-flex align-items-center pt-3">
                <b-form-checkbox
                  v-model="form.agree"
                  name="check-button"
                  checkbox
                  @change="checkboxError = false"
                >
                </b-form-checkbox>
                <span class="form-text">
                  Verisure mag mij telefonisch benaderen om mij gratis te
                  informeren over hun beveiligingsdiensten.
                </span>
              </div>
              <span
                class="text-danger text-left form-text"
                v-if="checkboxError"
              >
                Vink deze optie aan om verder te gaan
              </span>
              <div class="d-md-flex align-items-baseline">
                <b-button
                  class="form__btn py-3 px-4 pr-5 mt-3 d-flex align-items-center justify-content-center"
                  size="md"
                  type="submit"
                >
                  <b-spinner v-if="postcodeCheckerLoader" class="text-center" />
                  <span v-else class="pl-2 pl-md-0">Bevestigen</span>
                  <!-- <img
                    src="../../assets/rightArrowbtn.png"
                    v-if="!postcodeCheckerLoader"
                    class="ml-3"
                  /> -->
                  <svg
                    v-if="!postcodeCheckerLoader"
                    class="ml-3 mr-n4"
                    width="22"
                    height="12"
                    viewBox="0 0 22 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.8509 5.47064C20.8506 5.47037 20.8504 5.47005 20.8501 5.46977L16.5572 0.69228C16.2356 0.334382 15.7155 0.335714 15.3953 0.695404C15.0752 1.05505 15.0765 1.63675 15.3981 1.9947L18.2803 5.20223H0.881143C0.427388 5.20223 0.0595703 5.61356 0.0595703 6.12098C0.0595703 6.62841 0.427388 7.03973 0.881143 7.03973H18.2803L15.3981 10.2473C15.0765 10.6052 15.0753 11.1869 15.3954 11.5466C15.7155 11.9063 16.2357 11.9075 16.5573 11.5497L20.8502 6.77219C20.8504 6.77191 20.8506 6.77159 20.8509 6.77132C21.1727 6.41218 21.1716 5.82859 20.8509 5.47064Z"
                      fill="white"
                    />
                  </svg>
                </b-button>
                <p class="form__description ml-3 mb-2 mt-3">
                  Geheel vrijblijvend
                </p>
              </div>
              <p class="form__sub-description m-0 pb-3 pt-3">
                Door op “Bevestigen” te klikken ga je akkoord met de
                actievoorwaarden. Daarbij houdt Verisure je via e-mail op de
                hoogte over hun beveiligingssystemen. We verkopen je gegevens
                niet door aan derden.
              </p>

              <!-- <img
                src="../../assets/verisurehouse/arrow-down.png"
                class="d-md-block d-none arrow"
              /> -->
            </b-form>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="5"
        class="d-flex justify-content-center pt-2 align-items-center"
      >
        <img
          src="../../assets/alaramtesterheader.png"
          class="ml-md-5 rightImage"
          alt=""
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import person_icon from "@/assets/User1.png";
import mail_icon from "@/assets/email.png";
import phone_icon from "@/assets/telephone.png";
import btnArrow_icon from "@/assets/postcoode/btn_rightArrow.png";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PostCodeForm",
  data() {
    return {
      submited: false,
      profile: "",
      person_icon,
      mail_icon,
      phone_icon,
      btnArrow_icon,
      isChecked: false,
      checkboxError: false,
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      phoneValid: false,

      validationCheck: true,
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        site_custom_url: "https://verisure.verbeterthuis.nl/alarmtester",
        site_custom_name: "af_republish_alarmtester",
        agree: false,
      },
    };
  },
  methods: {
    ...mapActions(["postcodeCheckerLead"]),

    async onSubmit() {
      // this.$emit("handleNextClick");
      this.firstNameValid = !this.FnameValidationCheck;
      this.lastNameValid = !this.LnameValidationCheck;
      this.emailValid = !this.emailValidationCheck;
      this.phoneValid = !this.phoneValidationCheck;
      if (
        !this.firstNameValid &&
        !this.lastNameValid &&
        !this.emailValid &&
        !this.phoneValid &&
        !this.isChecked &&
        !this.form.agree
      ) {
        this.checkboxError = true;
      } else {
        // console.log("payload:",{ ...this.form, zip: this.getPostcode });
        const phoneNumberWithoutPunctuation = this.form.phone_number
          .replace(/[\s-]/g, "")
          .replace(/^00(\d+)$/, "$1");
        let resp = await this.postcodeCheckerLead({
          ...this.form,
          zip: this.getPostcode.zip,
          house_number: this.getPostcode.house_number,
          site_subid: this.$route.query.clickid,
          publisher_id: this.$route.query.site,
          answers: ["3332"],
          phone_number: phoneNumberWithoutPunctuation,
        });
        if (resp.success == true) {
          this.submited = true;
          this.profile = resp.profileid;
          this.$emit("handleNextClick", resp?.profileid);
        }
        // else {
        //   // If checkbox is not checked, show error
        //   if (!this.form.agree) {
        //     this.checkboxError = true;
        //   }
        // }
      }
    },
  },
  computed: {
    ...mapGetters([
      "postcodeCheckerLoader",
      "getPostcode",
      "postcodeCheckerName",
      "postcodeCheckerId",
    ]),

    FnameValidationCheck() {
      this.firstNameValid = false;
      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },

    LnameValidationCheck() {
      this.lastNameValid = false;
      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidationCheck() {
      this.phoneValid = false;
      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidationCheck() {
      this.emailValid = false;
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }

      if (
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-input-wrp {
  position: relative;
  width: 100%;
  // width: 50%;
}
.user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #777;
  box-shadow: none !important;
}
.user-input-wrp .inputText:focus {
  border-color: blue;
  border-width: medium medium 2px;
}
.user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  color: #b5b5b5;
  top: 16px;
  left: 10px;
  transition: 0.2s ease all;
}
.user-input-wrp input:focus ~ .floating-label,
.user-input-wrp input:not(:focus):valid ~ .floating-label {
  top: 5px;
  left: 10px;
  font-size: 13px;
  opacity: 1;
  color: #b5b5b5;
}

.form-text {
  font-size: 9px;
  font-weight: 500;
  text-align: start;
}
.rightImage {
  width: 500px;
  // height: 260px;
  @media (max-width: 1023.99px) {
    width: 100%;
    // height: 30%;
  }
  @media (max-width: 766.99px) {
    width: 100%;
    height: 100%;
  }
}
.postcodeForm {
  font-family: "Fira Sans", sans-serif;
  max-width: fit-content;
  //width: 42% !important;
  position: relative;
  @media (min-width: 830px) and (max-width: 899.98px) {
    .padding_md {
      padding: 0 20px !important;
    }
  }
  .postcodeBanner__zipInput--btn {
    font-weight: bold;
  }
  & .input-group-text {
    border-radius: 30px 0px 0px 30px !important;
    border: none !important;
  }
  & .form-control {
    border: none !important;
    border-left: none !important;
    background: #e9ecef !important;
    border-radius: 0px 30px 30px 0px !important;
    clip-path: inset(-5px -5px -5px 0px) !important;
    height: calc(1.75em + 2rem + 2px);

    &:focus {
      border-left: none !important;
      clip-path: inset(-5px -5px -5px 0px) !important;
    }
    &::placeholder {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 38px;
      color: #959595;
      font-weight: 400;
    }
  }
  &.input-group {
  }
  .arrow {
    top: -34px;
    right: -55px;
    position: absolute;
  }

  @media (max-width: 1023.99px) {
    //width: 50% !important;
  }

  @media (max-width: 767.99px) {
    width: 100% !important;
  }
}
.line {
  border-top: 1px solid #f1f1f1;
  width: 75%;
  margin: auto;
}
.form {
  font-family: "Fira Sans", sans-serif;

  &__heading {
    font-size: 30px;
    color: #000000;
    font-weight: 800;
    text-align: start;
    line-height: 40px;
  }
  &__heading-red {
    // color: #ff0033 !important;
  }
  &__main {
    width: 75% !important;
  }

  &__title {
    font-size: 25px;
    letter-spacing: -1px;
    line-height: 30px;
    color: #004380;
    font-weight: 700;

    text-align: start;
  }

  &__description {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    text-align: start;
    font-style: italic;
  }
  &__sub-description {
    font-size: 10px;
    color: #c1c1c1;
    font-weight: 400;

    text-align: start;
  }
  &__text {
    color: #000000;
    text-align: start;
    font-weight: 500;
    font-size: 21px;
  }
  &__btn {
    border-radius: 50px;
    font-size: 13px;
    background-color: #00b67a;
    border: none !important;
    // text-transform: uppercase;

    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 600;
    // min-width: 100%;
    position: relative;
    &:hover,
    &:focus,
    &:active {
      background-color: #00b67a !important;
      border: none !important;
      box-shadow: none !important;
    }
    img {
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1023.99px) {
    &__btn {
      font-size: 14px;
    }
    &__text {
      font-size: 15px;
    }
    &__heading {
      font-size: 23px;
      line-height: 30px;
    }

    &__main {
      width: 75% !important;
    }

    &__title {
      font-size: 18px;
      line-height: 20px;
    }

    &__description {
      font-size: 15px;
    }
    &__sub-description {
      font-size: 11px;
    }
  }
  @media (max-width: 767.99px) {
    &__btn {
      font-size: 13px;
    }

    &__heading {
      font-size: 20px;
      line-height: 25px;
    }

    &__title {
      font-size: 28px;
      line-height: 30px;
      //text-align: start;
      text-align: left;
    }
    &__text {
      font-size: 15px;
    }
    &__description {
      font-size: 15px;
    }
    &__sub-description {
      font-size: 13px;
    }
  }
  @media (max-width: 575.99px) {
    &__btn {
      text-align: start;
      font-size: 20px;
      border-radius: 60px;
    }
    &__text {
      font-size: 25px;
      text-align: start;
    }
    &__heading {
      font-size: 34px;
      line-height: 30px;
    }
  }
  .btn {
    &:hover,
    &:focus,
    &:active {
      background-color: #00b67a !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}
</style>
